import React from "react";
import { Flex, Container } from "theme-ui";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Wallet } from "@ethersproject/wallet";

import { Decimal, Difference, Trove } from "@liquity/lib-base";
import { LiquityStoreProvider } from "@liquity/lib-react";

import { useLiquity } from "./hooks/LiquityContext";
import { TransactionMonitor } from "./components/Transaction";
import { UserAccount } from "./components/UserAccount";
import { SystemStatsPopup } from "./components/SystemStatsPopup";
import { Header } from "./components/Header";

import { PageSwitcher } from "./pages/PageSwitcher";
import { CommingSoonPage } from "./pages/CommingSoonPage";
import { LendrREPage } from "./pages/LendrREPage";
// import { LendrPage } from "./pages/LendrPage";

import { TroveViewProvider } from "./components/Trove/context/TroveViewProvider";
import { StabilityViewProvider } from "./components/Stability/context/StabilityViewProvider";
import { StakingViewProvider } from "./components/Staking/context/StakingViewProvider";
import "tippy.js/dist/tippy.css"; // Tooltip default style
import { BondsProvider } from "./components/Bonds/context/BondsProvider";
import { UnipoolStakingViewProvider } from "./components/UnipoolStaking/context/UnipoolStakingViewProvider";
import { LiquidationViewProvider } from "./components/Liquidation/context/LiquidationViewProvider";
import { RedemptionViewProvider } from "./components/Redemption/context/RedemptionViewProvider";

type LiquityFrontendProps = {
  loader?: React.ReactNode;
};
export const LiquityFrontend: React.FC<LiquityFrontendProps> = ({ loader }) => {
  const { account, provider, liquity } = useLiquity();

  // For console tinkering ;-)
  Object.assign(window, {
    account,
    provider,
    liquity,
    Trove,
    Decimal,
    Difference,
    Wallet
  });

  return (
    <LiquityStoreProvider {...{ loader }} store={liquity.store}>
      <Router>
        <TroveViewProvider>
          <StabilityViewProvider>
            <StakingViewProvider>
              <UnipoolStakingViewProvider>
                <LiquidationViewProvider>
                  <RedemptionViewProvider>
                    <BondsProvider>
                      <Flex sx={{ flexDirection: "column", minHeight: "100%" }}>
                        <Header>
                          <UserAccount />
                          <SystemStatsPopup />
                        </Header>

                        <Container
                          variant="main"
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <Switch>
                            <Route path="/" exact>
                              <Redirect to="/lendrusd" />
                            </Route>
                            <Route path="/lendrgold">
                              <CommingSoonPage 
                                label="LendrGold is Coming Soon."
                                desc="LendrGold is a gold stablecoin pegged to a custom global gold index. LendrGold and LendrUSD will be launched once sufficient collateral liquidity has been established for LendrUSRE."
                              />
                            </Route>
                            <Route path="/lendrusd">
                              <PageSwitcher />
                              {/* <CommingSoonPage 
                                label="LendrUSD is Coming Soon."
                                desc="LendrUSD is an inflation proof stablecoin pegged to a custom USD index. LendrUSD and LendrGold will be launched once sufficient collateral liquidity has been established for LendrUSRE."
                              /> */}
                            </Route>
                            <Route path="/lendrre">
                              <LendrREPage />
                            </Route>
                            {/* <Route path="/lendrfee">
                              <LendrPage />
                            </Route> */}
                            {/* <Route path="/bonds">
                              <Bonds />
                            </Route> */}
                          </Switch>
                        </Container>
                      </Flex>
                    </BondsProvider>
                  </RedemptionViewProvider>
                </LiquidationViewProvider>
              </UnipoolStakingViewProvider>
            </StakingViewProvider>
          </StabilityViewProvider>
        </TroveViewProvider>
      </Router>
      <TransactionMonitor />
    </LiquityStoreProvider>
  );
};
