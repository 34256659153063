import { Container, Card, Box, Paragraph, Link } from "theme-ui";
import { InfoMessage } from "../components/InfoMessage";

export const LendrREPage: React.FC = () => (
  <Container
    variant="main"
    sx={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center"
    }}
  >
    <Card>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="Lendr Real Estate is Coming Soon!">
          <Paragraph>LendrUSRE is a flatcoin pegged to a US housing index. LendrGold and LendrUSRE will be launched once sufficient collateral liquidity has been established for LendrUSD.</Paragraph>
          <br />
          <Paragraph>
            Follow our <Link href="https://twitter.com/lendrnetwork" target="_blank">Twitter</Link> to stay up to date on all Lendr updates!
          </Paragraph>
        </InfoMessage>
        </Box>
    </Card>
  </Container>
);
