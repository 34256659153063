import { COIN, GT, G_TOKEN } from "./strings";

export type Lexicon = {
  term: string;
  description?: string;
  link?: string;
};

export const BORROW_FEE: Lexicon = {
  term: "Borrowing Fee",
  description:
    `The Borrowing Fee is a one-off fee charged as a percentage of the borrowed amount (in ${COIN}) and is part of a Trove's debt. The fee varies between 0.5% and 5% depending on ${COIN} redemption volumes.`
};

export const TVL = (chainId : number | undefined) : Lexicon => ({
  term: "TVL",
  description:
    `The Total Value Locked (TVL) is the total value of ${G_TOKEN[chainId || 1]} locked as collateral in the system, given in ${G_TOKEN[chainId || 1]} and USD.`
});

export const STAKED_LQTY: Lexicon = {
  term: `Staked ${GT}`,
  description: `The total amount of ${GT} that is staked for earning fee revenue.`
};

export const TCR = (chainId : number | undefined) : Lexicon => ({
  term: "Total Collateral Ratio",
  description:
    `The ratio of the Dollar value of the entire system collateral at the current ${G_TOKEN[chainId || 1]}:USD price, to the entire system debt.`
});

export const RECOVERY_MODE: Lexicon = {
  term: "Recovery Mode",
  description:
    "Recovery Mode is activated when the Total Collateral Ratio (TCR) falls below 150%. When active, your Trove can be liquidated if its collateral ratio is below the TCR. The maximum collateral you can lose from liquidation is capped at 110% of your Trove's debt. Operations are also restricted that would negatively impact the TCR."
};

export const STABILITY_POOL_LUSD: Lexicon = {
  term: `${COIN} in Stability Pool`,
  description:
    `The total ${COIN} currently held in the Stability Pool, expressed as an amount and a fraction of the ${COIN} supply.`
};

export const KICKBACK_RATE: Lexicon = {
  term: "Kickback Rate",
  description:
    `The fraction of ${GT} that will be paid out to Stability Pool stakers using this frontend. 100% = No fee. </br>Note: this fee ONLY applies to staking rewards, and is NOT taken from your staked amount.`
};

export const ETH = (chainId: number | undefined): Lexicon => ({
  term: G_TOKEN[chainId || 1],
  description: "Native token of this blockchain."
});

export const LUSD: Lexicon = {
  term: "USDL",
  description: "Lendr Network's stablecoin for an inflation-proof USD."
};

export const LQTY: Lexicon = {
  term: "LNDR",
  description: "The Lendr reward token for the LendrUSD stablecoin (USDL). Stake this token to receive fees from the LendrUSD lending system."
};

export const LGLD: Lexicon = {
  term: "LGLD",
  description: "Lendr Network's stablecoin for the global gold market."
};

export const LDRE: Lexicon = {
  term: "USRE",
  description: "Lendr Network's stablecoin for the US real estate market."
};

export const LQTYG: Lexicon = {
  term: "LNDRg",
  description: "The Lendr reward token for the LendrGold stablecoin (LGLD). Stake this token to receive fees from the LendrGold lending system."
};

export const LQTYR: Lexicon = {
  term: "LNDRr",
  description: "The Lendr reward token for the LendrUSRE stablecoin (USRE). Stake this token to receive fees from the LendrUSRE lending system."
};

export const TROVES: Lexicon = {
  term: "Troves",
  description: "The total number of active Troves in the system."
};

export const LUSD_SUPPLY: Lexicon = {
  term: `${COIN} supply`,
  description: `The total ${COIN} minted by the Liquity Protocol.`
};

export const PRICE_TARGET: Lexicon = {
  term: "Price Target",
  description: `The target price peg for ${COIN} calculated using our Custom USD Index (US CPI below).`
}

export const INFLATION_INDEX: Lexicon = {
  term: "US CPI",
  description: "Our custom unbiased US CPI based on over 10 million data points from 30+ verified sources."
}